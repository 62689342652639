import * as Sentry from '@sentry/vue';
import Vue from 'vue';
const ENV = process.env.VUE_APP_SENTRY_ENV || process.env.NODE_ENV;

if (!['development', 'test'].includes(process.env.VUE_APP_SENTRY_ENV || ENV) && !window.Cypress) {
    Sentry.init({
        Vue,
        dsn: 'https://fe2b29769c1344f0b5d37012e1698188@o527262.ingest.sentry.io/5658862',
        environment: ENV === 'production',
        integrations: [
            Sentry.browserTracingIntegration(),
            Sentry.vueIntegration({
                tracingOptions: {
                    trackComponents: true,
                },
            }),
        ],
        tracesSampleRate: 1.0,
    });
}
